@use '/src/domains/core/tamagoshiv2/tokens/spacings';
@import '@/core/tamagoshi/scss';
@import '@/retention/Homepage/styles/carousel';
@import '@/retention/Homepage/styles/section';

.container {
  padding-top: spacings.$l;
  padding-bottom: spacings.$l;
}

.controls {
  @extend %carouselControls;
}

.section {
  @extend %section;
}

.sectionTitle.sectionTitle {
  margin: 0;
  font-size: 20px;
}

.subTitle {
  font-size: 14px;
  color: $n700;
}

.slider {
  scroll-padding-left: spacings.$s;
  padding-top: spacings.$m;

  &:first-child {
    padding-left: 16px;
    margin-left: 16px;
  }
}

@include size-below('md') {
  .sectionTitle {
    margin-left: spacings.$m;
    padding-left: spacings.$m;
  }

  .subTitle {
    margin-left: spacings.$m;
  }

  .slider {
    scroll-padding-left: spacings.$m;
    padding-left: spacings.$m;

    &:after {
      content: '';
      display: block;
      flex: 0 0 spacings.$m;
      width: spacings.$m;
    }
  }
}

.categoryCard {
  &:not(:first-child) {
    margin-left: spacings.$s;

    @include size-above('md') {
      margin-left: spacings.$m;
    }
  }
}
