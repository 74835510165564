@use 'sass:math';
@import '@/core/tamagoshi/scss';
@import '@/core/tamagoshi/scss/variables';

.card {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  flex-shrink: 0;
  justify-content: flex-start;
  align-items: stretch;

  padding: 0;

  width: 280px;
  min-height: 232px;

  background-color: $n0;
  border-radius: $spacingXXs;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);

  text-decoration: none;
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 8px 12px 0 rgb(0 0 0 / 8%);
  }
}

.image {
  display: block;
  border-radius: $spacingXXs $spacingXXs 0 0;
  object-fit: cover;
  width: 280px;
  height: 176px;
  flex: 0 0 176px;
}

.title {
  flex: 1;

  margin: 0;
  padding: 0 $spacingMd;

  border-radius: 0 0 $spacingXXs $spacingXXs;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  text-align: left;

  @include text('body1', 'semiBold');
  color: $primaryColorDark;
}

.link {
  text-decoration: none;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
